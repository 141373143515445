define("discourse/plugins/discourse-calendar/discourse/initializers/discourse-post-event-decorator", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/plugins/discourse-calendar/discourse/components/discourse-post-event", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _pluginApi, _I18n, _discoursePostEvent, _discoursePostEventEvent, _guessBestDateFormat, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _validEventPreview(eventContainer1) {
    eventContainer1.innerHTML = "";
    eventContainer1.classList.add("discourse-post-event-preview");
    const statusLocaleKey1 = `discourse_post_event.models.event.status.${eventContainer1.dataset.status || "public"}.title`;
    if (_I18n.default.lookup(statusLocaleKey1, {
      locale: "en"
    })) {
      const statusContainer1 = document.createElement("div");
      statusContainer1.classList.add("event-preview-status");
      statusContainer1.innerText = _I18n.default.t(statusLocaleKey1);
      eventContainer1.appendChild(statusContainer1);
    }
    const datesContainer1 = document.createElement("div");
    datesContainer1.classList.add("event-preview-dates");
    const startsAt1 = moment.tz(eventContainer1.dataset.start, eventContainer1.dataset.timezone || "UTC");
    const endsAt1 = eventContainer1.dataset.end && moment.tz(eventContainer1.dataset.end, eventContainer1.dataset.timezone || "UTC");
    const format1 = (0, _guessBestDateFormat.default)(startsAt1, endsAt1);
    let datesString1 = `<span class='start'>${startsAt1.tz(moment.tz.guess()).format(format1)}</span>`;
    if (endsAt1) {
      datesString1 += ` → <span class='end'>${endsAt1.tz(moment.tz.guess()).format(format1)}</span>`;
    }
    datesContainer1.innerHTML = datesString1;
    eventContainer1.appendChild(datesContainer1);
  }
  function _invalidEventPreview(eventContainer1) {
    eventContainer1.classList.add("discourse-post-event-preview", "alert", "alert-error");
    eventContainer1.classList.remove("discourse-post-event");
    eventContainer1.innerText = _I18n.default.t("discourse_post_event.preview.more_than_one_event");
  }
  function _decorateEventPreview(api1, cooked1) {
    const eventContainers1 = cooked1.querySelectorAll(".discourse-post-event");
    eventContainers1.forEach((eventContainer1, index1) => {
      if (index1 > 0) {
        _invalidEventPreview(eventContainer1);
      } else {
        _validEventPreview(eventContainer1);
      }
    });
  }
  function initializeDiscoursePostEventDecorator(api) {
    api.decorateCookedElement((cooked, helper) => {
      if (cooked.classList.contains("d-editor-preview")) {
        _decorateEventPreview(api, cooked);
        return;
      }
      if (helper) {
        const post = helper.getModel();
        if (!post?.event) {
          return;
        }
        const postEventNode = cooked.querySelector(".discourse-post-event");
        if (!postEventNode) {
          return;
        }
        const wrapper = document.createElement("div");
        postEventNode.before(wrapper);
        const event = _discoursePostEventEvent.default.create(post.event);
        helper.renderGlimmer(wrapper, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                  <DiscoursePostEvent @event={{event}} />
                
        */
        {
          "id": "wx3uhHbS",
          "block": "[[[1,\"\\n          \"],[8,[32,0],null,[[\"@event\"],[[32,1]]],null],[1,\"\\n        \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/initializers/discourse-post-event-decorator.js",
          "scope": () => [_discoursePostEvent.default, event],
          "isStrictMode": true
        }), (0, _templateOnly.default)()));
      }
    }, {
      id: "discourse-post-event-decorator"
    });
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_auto_notification", "calendar-day");
    api.replaceIcon("notification.discourse_calendar.invite_user_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.invite_user_predefined_attendance_notification", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.before_event_reminder", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.after_event_reminder", "calendar-day");
    api.replaceIcon("notification.discourse_post_event.notifications.ongoing_event_reminder", "calendar-day");
  }
  var _default = _exports.default = {
    name: "discourse-post-event-decorator",
    initialize(container1) {
      const siteSettings1 = container1.lookup("service:site-settings");
      if (siteSettings1.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeDiscoursePostEventDecorator);
      }
    }
  };
});